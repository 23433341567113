/*
 * File: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp\src\afw\css\pace.css
 * Project: c:\Core 3.0 Projects\ExtADSignup\ExtADSignup\ClientApp
 * Created Date: Monday October 21st 2019
 * Author: Walton, Timothy
 * -----
 * Last Modified: Tuesday December 3rd 2019 9:56:14 am
 * Modified By: the developer known as Walton, Timothy
 * -----
 */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  background: #efefef;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 3px;
  position: absolute;
  top: 0px;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #FFF;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}